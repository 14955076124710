import { useUserStore } from '~/stores/user'

/**
 * The currently logged in user is of user.kind 'band' and agency.kind 'Pr'
 */
export default defineNuxtRouteMiddleware(() => {
  const { $localePath, $pinia } = useNuxtApp()
  const { IS_AGENCY } = useUserStore($pinia)

  if (!IS_AGENCY) return navigateTo($localePath('/'))
})
